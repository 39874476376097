@mixin respond($breakpoint) {
  // Phone-xs
  @if $breakpoint == phone-xs {
    @media (max-width: 20em) {
      @content;
    } //0 - 320px
  }
  // Phone
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      @content;
    } //0 - 600px
  }
  // Table small
  @if $breakpoint == tab-port-sm {
    @media (max-width: 48em) {
      @content;
    } //768px
  }
  // Table Portrait
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      @content;
    } //900px
  }
  // Table Landscape
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      @content;
    } //1200px
  }
  // Big Desktop
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      @content;
    } //1800px +
  }
}

// Border White Mixin
@mixin section-borders {
  border: 1.5rem solid #fff;
  border-top: 0;
  border-bottom: 0;
}

@mixin supportColorForIE11 {
  // Fall for text-gradient not supported in IE11
  color: $secondary-color;
  -webkit-text-fill-color: $secondary-color; // fallback
  @supports (-webkit-background-clip: text) or (background-clip: text) {
    background-image: linear-gradient(
      135deg,
      $primary-color 100%,
      $secondary-color 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
  }
}

@mixin supportIE11 {
  // Provide your basic styles to support IE11
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    @content;
  }
}
