// COLORS
$primary-color: #dc5405;
$secondary-color: #b07602;

$white-color: #fff;
$grey:#563333;

$dark-grey: #333333;
$light-grey: #d2d2d2;

$dark-blue-text: #272341; // For Headings

//For navbar
$color-blue: #00315c;
$color-purple: #6f479f;
$color-black: #202020;
$color-gray: #edebeb;
$color-white: #fcfcfc;

// FONT SIZE
$default-font-size: 1.6rem;
$big-font-size: 4rem;
$mid-font-size: 2.5rem;