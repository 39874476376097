#hero {
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 0px;
  background: $white-color;
  font-weight: 400;
  color: $dark-blue-text;
  padding: 0rem 5.6rem;
  margin-bottom: 0;
  top: 0rem;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;

  /* background: url("/src/assets/[your-image].png");
  background-position: center;
  background-size: cover; */

  @include respond(phone) {
    padding: 0rem $default-font-size;
  }
.clock {
  background-color: transparent;
  border:0;
  background-size: 20px 20px;
  margin: 5px;
  padding-right: 3px;
}

.dateandtimep {
  margin-left: 3px;
  padding-left: 3px;
}

.dateandtime{
display: flex;
justify-content: flex-start;
color: gray;
padding-inline: 3px;
margin-right: 5px;
margin-left: 3px;

}
.date-time {
  margin: 10px !important;
  padding-left: 10px !important;
}
  .hero-wrap {
    background-color: $white-color;
   // background-image: linear-gradient(
     ///// 135deg,
     // #2e8df9 20%,
     // #0E7DF9 100%
    //);
    display: flex;
    align-items: center;
    
  color: $white-color;
  height: 100%;
  border-top: 0px;
 // -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 87% 75%, 86% 100%, 76% 77%, 0% 75%);
  //clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 87% 75%, 86% 100%, 76% 77%, 0% 75%);
  padding-bottom: 4%;
  padding-top: 2%;
  
  border-radius: 10px !important;
  @include respond(phone) {
    margin-top: 5rem;
  //  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 86%, 75% 87%, 75% 100%, 50% 88%, 0 88%);
  //clip-path: polygon(0% 0%, 100% 0%, 100% 86%, 75% 87%, 75% 100%, 50% 88%, 0 88%);
  }
  }
  .log {
    height: 38rem;
    margin-left: 3rem;
    @include respond(phone) {
      height: 20rem;
    }
    @include respond(tab-land) {
      height: 20rem;
    }
  }

  .hero-title {
    font-size: 5.6rem;
    font-weight: 700;
    margin-bottom: 3.2rem;
    color: lightgray;
  
    text-align: left;

    @include respond(tab-land) {
      font-size: 4rem;
    }
    @include respond(tab-port) {
      font-size: 3.6rem;
      text-align: center;
    }
    @include respond(phone) {
      font-size: 3rem;
      line-height: 1.5;
    }
    @include respond(phone-xs) {
      font-size: 2.5rem;
    }
  }

  .hero-cta {
    display: flex;

    @include respond(tab-port) {
      justify-content: center;
    }

    & a {
      font-size: 2.4rem;

      @include respond(phone) {
        font-size: 2rem;
      }
    }
  }
}
